document.addEventListener('DOMContentLoaded', function () {
  // 全ページ共通の機能
  setupHamburgerMenu();

  const path = window.location.pathname;

  // トップページ専用の機能
  if (path === '/' || path.match(/^\/index.html$/)) {
    smoothScroll();
    scrollToHashOnLoad();
    voiceCarousel();
    setupFaqAccordion();
    initMouseStalkerAndAnimations();
  }

  // サービスページ専用の機能
  if (path === '/service.html') {
    setupFaqAccordion();
    scrollToHashOnLoad();
  }

  // インタビューページ専用の機能
  if (path === '/about.html') {
    setupFaqAccordion();
    scrollToHashOnLoad();
  }

  // お問い合わせページ専用の機能
  if (path === '/contact.html') {
    scrollToHashOnLoad();
  }
});

// ハンバーガーボタン・ドロワーメニュー開閉制御
function setupHamburgerMenu() {
  // ハンバーガーボタンとドロワーナビゲーションの選択
  const hamburgers = document.querySelectorAll('.js-hamburger');
  const drawers = document.querySelectorAll('.js-drawer');

  // 各ハンバーガーボタンにイベントリスナーを追加
  hamburgers.forEach((hamburger, index) => {
    hamburger.addEventListener('click', () => {
      const expanded = hamburger.getAttribute('aria-expanded') === 'true';
      hamburger.classList.toggle('active');
      hamburger.setAttribute('aria-expanded', !expanded);

      // 対応するドロワーを取得して制御
      const drawer = drawers[index];
      if (drawer) {
        drawer.classList.toggle('active');
        drawer.setAttribute('aria-hidden', expanded);

        // ドロワーがアクティブなときにスクロールを防止
        document.body.style.overflow = drawer.classList.contains('active') ? 'hidden' : 'auto';
      }
    });
  });
  // ドロワーリンクのクリックイベント処理
  document.querySelectorAll('.js-drawer__link').forEach(link => {
    link.addEventListener('click', () => {
      const drawer = link.closest('.js-drawer');
      const hamburger = hamburgers[Array.from(drawers).indexOf(drawer)];

      if (drawer) {
        drawer.classList.remove('active');
        hamburger.classList.remove('active');
        hamburger.setAttribute('aria-expanded', 'false');
        drawer.setAttribute('aria-hidden', 'true');

        // ドロワーが閉じたらスクロールを再び有効化
        document.body.style.overflow = 'auto';
      }
    });
  });
}
// トップページMVの初期アニメーション挙動
function initMouseStalkerAndAnimations() {
  // 要素の取得
  const mouseStalker = document.querySelector('#js-mouse'); // マウスストーカー要素
  const pMvSection = document.querySelector('.p-mv'); // p-mvセクション
  const pMvBlocks = document.querySelectorAll('.p-mv__block'); // 各p-mv__block要素
  const heading = document.querySelector('.p-mv__heading'); // ヘッディング要素
  const logo = document.querySelector('.p-mv__logo'); // ロゴ要素
  const words = document.querySelectorAll('.p-mv__word'); // 各ワード要素

  let currentIndex = 0; // 現在処理中のp-mv__blockのインデックス
  const isSP = window.matchMedia('(max-width: 767px)').matches; // SP版かどうかを判定
  let isMouseStalkerVisible = true; // マウスストーカーの現在の表示状態を管理
  let isAnimationComplete = false; // 全てのブロックがフェードアウトし、最終アニメーションが完了したか
  const scrollThreshold = isSP ? 180 : 100; // SPでは50px、PCでは100pxを許容スクロール範囲に設定

  // デバウンス関数の定義
  function debounce(func, wait) {
    let timeout;
    return function (...args) {
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(this, args), wait);
    };
  }

  // マウスストーカーの表示切り替え処理
  function toggleMouseStalker() {
    // 最終アニメーションが完了している場合は再表示しない
    if (isAnimationComplete) {
      gsap.to(mouseStalker, {
        opacity: 0,
        duration: 0.3,
        onComplete: () => {
          mouseStalker.style.display = 'none';
        }
      });
      return;
    }

    const rect = pMvSection.getBoundingClientRect(); // p-mvセクションの位置を取得

    // スクロール範囲内の表示制御
    if (rect.top < -scrollThreshold || rect.bottom > window.innerHeight + scrollThreshold) {
      if (isMouseStalkerVisible) {
        gsap.to(mouseStalker, {
          opacity: 0,
          duration: 0.3,
          onComplete: () => {
            mouseStalker.style.display = 'none';
          }
        });
        isMouseStalkerVisible = false; // 非表示に設定
      }
    } else {
      if (!isMouseStalkerVisible) {
        mouseStalker.style.display = 'flex';
        gsap.to(mouseStalker, { opacity: 1, duration: 0.3 });
        isMouseStalkerVisible = true; // 表示に設定
      }
    }
  }

  // PC版のみのマウス追従ロジック
  if (!isSP) {
    document.addEventListener('mousemove', function (e) {
      const x = e.clientX; // マウスのX座標
      const y = e.clientY; // マウスのY座標
      mouseStalker.style.transform = `translate(${x}px, ${y}px)`; // マウスストーカーをマウスの位置に移動
    });
  }

  // スクロールイベントでマウスストーカーの表示を切り替え
  window.addEventListener('scroll', debounce(toggleMouseStalker, 50));

  // セクションがクリックされたときの動作
  pMvSection.addEventListener('click', function () {
    if (currentIndex < pMvBlocks.length) {
      pMvBlocks[currentIndex].classList.add('fade-out'); // 現在のブロックをフェードアウト
      currentIndex++; // 次のブロックに進む

      // 全てのブロックが消えた場合の処理
      if (currentIndex === pMvBlocks.length) {
        // 500ms後にヘッディング、ロゴ、ワードを順次表示
        setTimeout(() => {
          const timeline = gsap.timeline();

          // ヘッディングとロゴを表示
          timeline.to([heading, logo], {
            opacity: 1,
            visibility: 'visible',
            y: 0,
            duration: 1.2,
            ease: 'power2.out',
            stagger: 0.3
          });

          timeline.to(words, {
            opacity: 1,
            visibility: 'visible',
            y: 0,
            duration: 1.5,
            ease: 'power2.out',
            stagger: 0.2
          });

          // マウスストーカーを完全に非表示
          gsap.to(mouseStalker, { display: 'none', duration: 0 });
          isAnimationComplete = true; // 最終アニメーションが完了したことを設定
        }, 500);
      }
    }
  });

  // 初回ロード時にマウスストーカーの状態を設定
  toggleMouseStalker();
}
// トップページ内でのスムーズスクロール
function smoothScroll() {
  // ページ内の全てのアンカーリンクを取得
  const links = document.querySelectorAll('a[href*="#"]');
  const header = document.querySelector('.js-header');
  const headerHeight = header ? header.offsetHeight : 0;

  links.forEach(link => {
    link.addEventListener('click', event => {
      const targetId = link.getAttribute('href');

      if (!targetId.startsWith('#')) return;

      const targetElement = document.querySelector(targetId);
      if (targetElement) {
        event.preventDefault();
        const targetElementPosition = targetElement.getBoundingClientRect().top + window.pageYOffset;
        const setPosition = targetElementPosition - headerHeight;

        window.scrollTo({
          top: setPosition,
          behavior: 'smooth'
        });

        // ドロワーメニューを閉じる処理
        const drawers = document.querySelectorAll('.js-drawer');
        const hamburgers = document.querySelectorAll('.js-hamburger');
        drawers.forEach((drawer, index) => {
          if (drawer.classList.contains('active')) {
            drawer.classList.remove('active');
            drawer.setAttribute('aria-hidden', 'true');
            hamburgers[index]?.classList.remove('active');
            hamburgers[index]?.setAttribute('aria-expanded', 'false');
            document.body.style.overflow = 'auto';
          }
        });
      }
    });
  });
}
// 下層ページからトップページに遷移した際のスムーズスクロール
function scrollToHashOnLoad() {
  // URLにハッシュが含まれているか確認
  if (window.location.hash) {
    // 遷移した時のハッシュを保存
    const initialHash = window.location.hash;

    // 一時的にハッシュを削除
    history.replaceState(null, null, ' ');

    window.addEventListener('load', function () {
      // ヘッダーの高さを取得
      const headerHeight = document.querySelector('.js-header').offsetHeight || 0;

      // 保存したハッシュが存在するか再確認
      if (initialHash) {
        // ハッシュに対応するIDを持つ要素を取得
        const targetElement = document.querySelector(initialHash);

        // 存在するハッシュに対応するIDの要素がある場合に処理を実行
        if (targetElement) {
          // 対応するIDの要素のページ上の位置の高さを取得
          const targetElementPosition = targetElement.getBoundingClientRect().top + window.pageYOffset;

          // ヘッダーの高さを引く
          const setPosition = targetElementPosition - headerHeight;

          // URLに元の遷移時のハッシュを付与
          history.replaceState(null, null, initialHash);

          // スムーズスクロール
          window.scrollTo({
            top: setPosition,
            behavior: 'smooth'
          });

          // ドロワーメニューを閉じる処理
          const drawers = document.querySelectorAll('.js-drawer');
          const hamburgers = document.querySelectorAll('.js-hamburger');
          drawers.forEach((drawer, index) => {
            if (drawer.classList.contains('active')) {
              drawer.classList.remove('active');
              drawer.setAttribute('aria-hidden', 'true');
              hamburgers[index]?.classList.remove('active');
              hamburgers[index]?.setAttribute('aria-expanded', 'false');
              document.body.style.overflow = 'auto';
            }
          });
        }
      }
    });
  }
}
// アコーディオン開閉
function setupFaqAccordion() {
  const initializeAccordion = details => {
    const summary = details.querySelector('summary');
    const panel = details.querySelector('summary + *');
    const crossIconPC = summary.querySelector('.js-faq-arrow.u-hidden-sp'); // PC用アイコン
    const crossIconSP = summary.querySelector('.js-faq-arrow.u-hidden-pc'); // SP用アイコン

    if (!(details && summary && panel)) {
      return; // 必要要素が揃っていない場合は処理をやめる
    }

    let isTransitioning = false; // 連打防止フラグ

    const onOpen = () => {
      if (details.open || isTransitioning) return;
      isTransitioning = true;
      panel.style.gridTemplateRows = '0fr';
      details.setAttribute('open', '');

      // 回転処理
      if (window.matchMedia('(max-width: 767px)').matches && crossIconSP) {
        crossIconSP.style.transform = 'rotate(-180deg)';
      } else if (crossIconPC) {
        crossIconPC.style.transform = 'rotate(-180deg)';
      }

      requestAnimationFrame(() => {
        panel.style.gridTemplateRows = '1fr';
      });
      panel.addEventListener(
        'transitionend',
        () => {
          isTransitioning = false;
        },
        { once: true }
      );
    };

    const onClose = () => {
      if (!details.open || isTransitioning) return;
      isTransitioning = true;
      panel.style.gridTemplateRows = '0fr';

      // 回転リセット処理
      if (window.matchMedia('(max-width: 767px)').matches && crossIconSP) {
        crossIconSP.style.transform = '';
      } else if (crossIconPC) {
        crossIconPC.style.transform = '';
      }

      panel.addEventListener(
        'transitionend',
        () => {
          details.removeAttribute('open');
          panel.style.gridTemplateRows = '';
          isTransitioning = false;
        },
        { once: true }
      );
    };

    summary.addEventListener('click', event => {
      event.preventDefault();
      if (details.open) {
        onClose();
      } else {
        onOpen();
      }
    });
  };

  const accordions = document.querySelectorAll('.js-faq-accordion');
  accordions.forEach(accordion => {
    initializeAccordion(accordion);
  });
}
// 体験者の声スライダー
function voiceCarousel() {
  var splideOptions = {
    type: 'loop',
    perPage: 1, //ページネーションを枚数分表示させる
    perMove: 1,
    arrows: true,
    pagination: true,
    // gap: '80px',//splide__trackにmargin-leftでデザイン再現
    drag: true, // ドラッグを有効化
    easing: 'ease-in-out',
    speed: 500,
    clones: 3, //最後のスライドがパッと消えないようにする
    autoplay: true,
    interval: 3000, // スライド切り替え時間
    pauseOnHover: false, // デフォルトのホバー時に一時停止の設定を無効化
    pauseOnFocus: true, // フォーカス時に一時停止
    classes: {
      arrows: 'p-voice__arrows', // 矢印全体のクラス
      arrow: 'p-voice__arrow', // 矢印ボタンの共通クラス
      prev: 'p-voice__arrow-prev', // 前矢印ボタン
      next: 'p-voice__arrow-next' // 次矢印ボタン
    },
    breakpoints: {
      767: {
        perPage: 1, // SPでは1枚ずつ表示
        focus: 'center' // 中央をフォーカス
      }
    }
  };

  const splide = new Splide('#voice-carousel', splideOptions);

  // `mounted` イベントでデフォルトクラスを削除
  splide.on('mounted', () => {
    const arrows = document.querySelector('.p-voice__arrows');
    if (arrows) {
      // デフォルトのクラスを削除
      arrows.classList.remove('splide__arrows', 'splide__arrows--ltr');
    }

    const prevArrow = document.querySelector('.p-voice__arrow-prev');
    if (prevArrow) {
      prevArrow.classList.remove('splide__arrow', 'splide__arrow--prev');
    }

    const nextArrow = document.querySelector('.p-voice__arrow-next');
    if (nextArrow) {
      nextArrow.classList.remove('splide__arrow', 'splide__arrow--next');
    }
  });

  // スライダーをマウント
  splide.mount();
}
